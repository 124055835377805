import {useState} from 'react';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import {brandDarkBlue} from 'utils/theme';
import {EVENTS, trackEvent} from 'utils/analytics';

const numberFormatter = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});

interface Props {
  dataPerCpu: number;
  dataPerUnit: number;
  unitPrice: number;
}

let interacted = false;

export const Calculator = ({dataPerCpu, dataPerUnit, unitPrice}: Props) => {
  const [cpus, setCpus] = useState(250);
  const data = cpus * dataPerCpu;
  const price = Math.ceil(data / dataPerUnit) * unitPrice;
  return (
    <div className="flex flex-col items-center gap-6">
      <div className="text-2xl font-semibold lg:text-3xl">Pricing Calculator</div>
      <div className="text-brand-gray max-w-xl text-center font-sans text-base font-light">
        Estimate your usage when using Polar Signals Cloud. On average 1 vCPU produces {dataPerCpu}
        GB per month.
      </div>
      <div className="flex items-center gap-4 rounded-lg bg-white p-6 font-sans text-xs text-gray-900">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <span>{cpus} vCPUs</span>
            <span className="text-gray-500">{numberFormatter.format(data)} GiB</span>
          </div>
          <div className="w-[512px] max-w-[50vw]">
            <Slider
              onChange={nextValues => {
                if (!interacted) {
                  trackEvent(EVENTS.PRICING_CALCULATOR_INTERACTED);
                  interacted = true;
                }

                setCpus(nextValues as number);
              }}
              value={cpus}
              min={1}
              max={1000}
              defaultValue={250}
              step={1}
              trackStyle={{
                background:
                  'linear-gradient(90deg, #4C68F6 -0.34%, #74D7F2 53.3%, #A7EAD5 100.14%)',
              }}
              handleStyle={{
                background: brandDarkBlue,
                border: 'none',
              }}
            />
          </div>
        </div>
        <div className="h-12 w-[1px] bg-gray-200" />
        <div className="w-12 items-end md:w-20">
          <span className="text-[8px]">$</span>
          <span className="mx-[1px] font-bold">{numberFormatter.format(price)}</span>
          <span className="text-[8px] text-gray-500">/mo</span>
        </div>
      </div>
    </div>
  );
};
