import cx from 'classnames';
import {Icon} from '@iconify/react';

interface Props {
  name: string;
  price: string | number;
  rate?: string;
  description: string;
  featuresTitle?: string;
  features?: string[];
  ctaText: string;
  ctaLink: string;
  isActive?: boolean;
  onCTAClick?: () => void;
}

export const PlanCard = ({
  name,
  price,
  rate,
  description,
  featuresTitle,
  features = [],
  ctaText,
  ctaLink,
  isActive = false,
  onCTAClick = () => {},
}: Props) => {
  return (
    <div
      className={cx('rounded-lg p-[2px] md:w-[60vw] lg:w-[350px]', {'bg-red1-900': isActive})}
      style={
        isActive
          ? {
              background:
                'linear-gradient(135deg, rgba(76,104,246,0.32) 0%, rgba(255,237,239,1) 35%, rgba(116,215,242,1) 65%, rgba(167,234,213,1) 100%)',
            }
          : undefined
      }
    >
      <div className="hover:shadow-allSideOuterLg flex   cursor-default flex-col gap-[14px] rounded-lg bg-white px-6 py-8 font-sans text-base transition-shadow">
        <div className="text-base uppercase text-gray-700 lg:text-lg">{name}</div>
        <div className="font-dazzed flex flex-col gap-6">
          {typeof price === 'number' ? (
            <div className="flex gap-[5px] text-gray-800">
              <span className="font-sans text-2xl leading-[44px]">$</span>
              <span className=" text-[48px] font-semibold leading-[52px] lg:text-[56px]">
                {price}
              </span>
            </div>
          ) : (
            <div className="text-2xl font-semibold uppercase text-gray-800 lg:text-3xl">
              {price}
            </div>
          )}
          {rate != null ? <div className="text-gray-600">{rate}</div> : null}
        </div>
        <div className="font-sans text-sm font-light text-gray-500 lg:text-base">{description}</div>
        {featuresTitle != null ? (
          <div className="flex flex-col gap-[10px]">
            <div className="text-black">{featuresTitle}</div>
            {features.map(feature => (
              <div className="flex items-center gap-2 font-light text-gray-800" key={feature}>
                <Icon
                  icon="solar:check-circle-bold-duotone"
                  className="text-green-500"
                  fontSize={28}
                />
                <span className="text-sm font-light lg:text-base">{feature}</span>
              </div>
            ))}
          </div>
        ) : null}
        <a
          href={ctaLink}
          className={cx(
            'hover:bg-brand-dark-blue w-full rounded px-8 py-5 text-center text-base font-medium opacity-95 hover:text-white hover:opacity-100',
            {
              'bg-brand-dark-blue text-white opacity-100': isActive,
              'bg-brand-dark-blue-faded text-brand-dark-blue': !isActive,
            }
          )}
          onClick={onCTAClick}
        >
          {ctaText}
        </a>
      </div>
    </div>
  );
};
