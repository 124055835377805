import cx from 'classnames';
import {PlanCard} from './PlanCard';
import {Calculator} from './Calculator';
import {trackScheduleACallClick, trackSignUpClick} from 'utils/analytics';

interface Props {
  paddingTop?: string;
}

const Pricing = ({paddingTop = ''}: Props) => {
  return (
    <section className="bg-gradient-to-tr from-[#4C68F699] to-[#4C68F6] text-white">
      <div className="w-full bg-gradient-to-b from-[#a7ead533] to-[#A7EAD500]">
        <div
          className={cx(
            'container mx-auto flex w-full flex-col items-center gap-12 px-4 py-12 lg:gap-24 lg:py-32',
            {[paddingTop]: !!paddingTop}
          )}
        >
          <div className="flex max-w-[790px] flex-col items-center gap-4">
            <div className="lg:text-4.5xl text-center text-3xl font-semibold">
              Simple and Transparent Pricing
            </div>
            <div className="text-brand-gray px-1 text-center font-sans text-base md:px-16 lg:px-10 lg:text-xl">
              Our pricing is based on a usage-based model which means you only pay for the profiling
              data you send to us.
            </div>
          </div>
          <div className="flex flex-col gap-11 text-black lg:flex-row lg:items-center">
            <PlanCard
              name="free trial"
              price="free"
              rate="for 14 days"
              description="A 14 day trial experience to experience Polar Signals Cloud and get acquainted with how useful continuous profiling can be to your infrastructure and application."
              ctaLink="https://cloud.polarsignals.com/signup"
              ctaText="Start free 14-day trial"
              onCTAClick={() => trackSignUpClick('pricing-trial-cta')}
            />
            <PlanCard
              name="pro"
              price={50}
              rate="per 10GB sent per month"
              description="A usage-based plan for teams with unlimited access to Polar Signals Cloud. Only pay for what you use with no fixed contract."
              featuresTitle="What’s included:"
              features={[
                'Unlimited collaborators',
                'Profile Multiple Languages',
                'Unlimited Organizations',
                'Unlimited Projects',
                'Discord & Slack Support',
              ]}
              ctaText="Get started with profiling"
              ctaLink="https://cloud.polarsignals.com/signup"
              isActive={true}
              onCTAClick={() => trackSignUpClick('pricing-pro-cta')}
            />
            <PlanCard
              name="enterprise"
              price="custom"
              description="For bigger businesses, looking for Premium Enterprise Support, custom SLA’s, or very large deployments."
              featuresTitle="All features in Pro Plan plus: "
              features={['Custom Contracts', 'Volume Discounts', 'Premium Enterprise Support']}
              ctaText="Talk to Sales"
              ctaLink="/schedule-a-call"
              onCTAClick={() => trackScheduleACallClick('pricing-enterprise-cta')}
            />
          </div>
          <Calculator dataPerCpu={0.2} dataPerUnit={10} unitPrice={50} />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
