module.exports = {
  content: ['./src/pages/**/*.{js,ts,jsx,tsx,mdx}', './src/components/**/*.{js,ts,jsx,tsx,mdx}'],
  darkMode: 'class',
  theme: {
    extend: {
      colors: {
        'brand-dark-blue': '#4C68F6',
        'brand-dark-blue-faded': '#CAD3FF',
        'brand-sky-blue': '#74D7F2',
        'brand-green': '#A7EAD5',
        'brand-coral': '#FF8278',
        'brand-pink': '#FFEDEF',
        'brand-gray': '#E6E9E6',
      },
      animation: {
        vibrate: 'vibrate 0.8s ease 1',
      },
      keyframes: {
        vibrate: {
          '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
          },
          '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
          },
          '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
          },
          '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
          },
        },
      },
      typography: {
        DEFAULT: {
          css: {
            blockquote: {
              '& > p:after': {
                content: 'none',
              },
              '& > p:before': {
                content: 'none',
              },
            },
          },
        },
      },
      fontFamily: {
        sans: ['Inter', 'sans-serif'],
        dazzed: ['Dazzed', 'sans-serif'],
      },
      fontSize: {
        '1xl': '1.375rem',
        '2.5xl': '1.75rem',
        '3.5xl': '2rem',
        '4.5xl': '2.5rem',
      },
      boxShadow: {
        allSideOuter: '0px 0px 10px 0px rgba(0,0,0,0.1);',
        allSideOuterLg: '0px 0px 10px 0px rgba(0,0,0,0.5);',
      },
      gap: {
        7.5: '1.875rem',
        11.5: '2.875rem',
        17.5: '4.375rem',
        25: '6.25rem',
      },
      padding: {
        11.5: '2.875rem',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require('@tailwindcss/typography')],
};
